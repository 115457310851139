$(document).ready(function() {

    /*******************************************/
    //Form Validation
    $('.validate-form').each(function() {
        $(this).validate({
            submitHandler: function(form) {
                form.submit();
            },
            rules: {
                required: true
            },
            errorPlacement: function(error, element) {
                error.appendTo(element.parents('.form-field'));
            },
            highlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).parents('.form-field').addClass(errorClass).removeClass(validClass);
                }
            },
            unhighlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).parents('.form-field').removeClass(errorClass).addClass(validClass);
                }
            }
        });
    });


    /*******************************************/
    //Custom Select Dropdown 
    $(function() {
        $('.custom-select-dropdown').selectric();
    });

    /*******************************************/
    //Lightbox Initiations
    $('.open-popup-link').magnificPopup({
        type:'inline',
        showCloseBtn: true,
        closeOnBgClick: true,
        removalDelay: 300,
        mainClass: 'mfp-zoom-in',
        midClick: true
    });

    $('.lightbox-manual-close-trigger').click(function(event) {
        e.preventDefault();
        $.magnificPopup.close();
    });

    /*******************************************/
    // Tabs
    $('.js-tab-triggers a').on('click', function(e) {
        e.preventDefault();
        $('.js-tab-triggers a').not(this).removeClass('active');
        $(this).addClass('active');
        var getTarget = $(this).attr('href');
        $('.tab-content').not(getTarget).hide();
        $(getTarget).show();
    });

    /*******************************************/
    // Nav Trigger
    $('.js-nav-trigger').on('click', function(){
        $('body').toggleClass('nav-open');
    });

    /*******************************************/
    // Scroll to Trigger
    $('.js-scrollto-trigger').on('click', function(){
        var $target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $($target).offset().top
        }, 1000);
    });

    window.setInterval(function(){
        checkIfScrolled();
    }, 500);
    
    function checkIfScrolled () {
        if(window.scrollY >= 1) {
            document.body.classList.add('doc-scrolled');
        } else {
            document.body.classList.remove('doc-scrolled');
        }
    }
});


/*******************************************/
$.fn.toggleHTML = function(t1, t2) {
    if (this.html() == t1) this.html(t2);
    else this.html(t1);
    return this;
};


/*******************************************/
//Main Menu
// function slideMenu(action){ 
//     var $menu = $('#mobile-menu') 
//     if (!action) { toggle(); return }
//     if (action == "open") { open(); return }
//     if (action == "close") { close(); return }

//     function open(){
//         $("body").addClass('open-menu');
//         $menu.attr('status', 'open');
//     }
//     function close(){
//         $("body").removeClass('open-menu');
//         $menu.attr('status', 'closed');
//     }
//     function toggle(){
//         var status =  $menu.attr('status');
//         if (status == "open"){ close(); return }
//         if (status == "closed"){ open(); return }
//     }
// }

// $('#mobile-menu li').each(function(){
//         $(this).has("ul").addClass('mobile-menu-parent');
// });

// $( ".mobile-menu-parent .icon" ).on("click", function(){
//     $(this).siblings('ul').slideToggle(150);
// });

// //prevent slide up
// $(".mobile-menu-parent").on("click", "ul", function(event){
//     event.stopPropagation()
// })

/*******************************************/
// Header Highlighter
// $(document).ready(function() {
//     var headerHref = "#site-header";
//     var mobileHref = "#mobile-menu";
//     var myURL = "/" + location.pathname.split('/')[1];

//     $(headerHref + ' li').each(function(){
//         if ($(this).find('a').attr('href') == myURL) {
//             $(this).find('a').addClass("selected")
//             return
//         }
//     })

//     $(mobileHref + ' li').each(function(){
//         if ($(this).find('a').attr('href') == myURL) {
//             $(this).find('a').addClass("selected")
//             return
//         }
//     })
// });

/*******************************************/



